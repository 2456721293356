import React from "react";
import "styles/global.css";
import iconInstagram from "../../static/images/icon-instagram.svg";
import iconFacebook from "../../static/images/icon-facebook.svg";
import {graphql, useStaticQuery} from "gatsby";
import { Trans } from "@lingui/macro";

const ContactInfo = ({locale}) => {
    const query = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    profile {
                        name
                        street
                        town
                        zip
                        state
                        companyId
                        taxId
                        vatId
                        iban
                        telephone
                        email
                        facebookProfile
                        instagramProfile
                    }
                }
            }
        }
    `);

  return (
      <div className="ContactInfo">
          <h3><Trans>Kontakt</Trans></h3>
          <p>
              <strong><Trans>Tel.</Trans></strong> {query.site.siteMetadata.profile.telephone}<br/>
              <strong><Trans>E-mail</Trans>:</strong> <a href={"mailto:"+query.site.siteMetadata.profile.email}>{query.site.siteMetadata.profile.email}</a>
          </p>
          <p>
              <a href={query.site.siteMetadata.profile.instagramProfile} title="Instagram">
                  <img className="ico" src={iconInstagram} alt="Instagram"/>
              </a>
              <a href={query.site.siteMetadata.profile.facebookProfile} title="Facebook">
                  <img className="ico" src={iconFacebook} alt="Facebook"/>
              </a>
          </p>
          <h3><Trans>Fakturačné údaje</Trans></h3>
          <p>
              <strong>{query.site.siteMetadata.profile.name}</strong><br/>
              {query.site.siteMetadata.profile.street}<br/>
              {query.site.siteMetadata.profile.zip} {query.site.siteMetadata.profile.town}
          </p>
          <p>
              {/* <strong><Trans>Bankové spojenie</Trans>:</strong> {query.site.siteMetadata.profile.iban}<br/> */}
              <strong><Trans>IČO</Trans>:</strong> {query.site.siteMetadata.profile.companyId}<br/>
              <strong><Trans>DIČ</Trans>:</strong> {query.site.siteMetadata.profile.taxId}<br/>
              <strong><Trans>IČ DPH</Trans>:</strong> {query.site.siteMetadata.profile.vatId}<br/>
          </p>
      </div>
)};

export default ContactInfo;
