import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans } from "@lingui/macro";
import axios from "axios";
import {
  FormGroup,
  Alert
} from "reactstrap";
import Reaptcha from "reaptcha";
import "styles/global.css";


export default function ContactForm({title, locale}) {
  const [isSuccess, setIsSuccess] = useState(false);
  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);
  const [recaptcha, setRecaptcha] = useState("");
  const [showValid, setShowValid] = useState(false);
  const { register, handleSubmit, errors, reset } = useForm();
  const onSubmit = data => {
      if(recaptcha.length < 1){
          setShowValid(true);
      }else{
          setShowValid(false);
          data.token = recaptcha;
          axios({
              method: "POST",
              url: process.env.API_URL,
              data: data,
              headers :{
                  'Content-Type': 'application/json'
              }
          }).then((response)=>{
              setIsSuccess(true);
              setRecaptcha("");
              setVisible(false);
              reset();
          })
          .catch((error)=>{
              console.log(error);
              setVisible(true);
          })
      }
  };

  const onVerify = recaptchaResponse => {
    setRecaptcha(recaptchaResponse);
  };

  return (
    <div className='ContactForm'>
        {isSuccess ?
            <div className="success-form-submit">
                <h3>Vaša správa bola úspešne odoslaná</h3>
                <p>Vážime si, že ste nás kontaktovali. Jeden z našich pracovníkov Vás bude kontaktovať v krátkom čase!</p>
                <p>Prajeme vám pekný deň.</p>
                <button onClick={()=>setIsSuccess(false)} className="button-basic primary-invert">Poslať novú správu</button>
            </div>
            :
            <form onSubmit={handleSubmit(onSubmit)}>
                <h3>{title}</h3>

                <Alert color="danger" isOpen={visible} toggle={onDismiss} fade={false}>
                   Posím, skúste znova, odosielanie zlyhalo.
                </Alert>

                <div className="form-row">
                    <label htmlFor="name"><Trans>Meno</Trans>/<Trans>firma</Trans> {errors.name && <span className="requirements"><Trans>Povinný údaj</Trans></span>}</label>
                    <input type="text" placeholder="Meno/firma" className="form-control" name="name"
                           ref={register({required: true, maxLength: 80})}/>
                </div>
                <div className="form-row">
                    <label htmlFor="email"><Trans>Email</Trans> {errors.email && <span className="requirements">{errors.email.message || <Trans>Povinný údaj</Trans>}</span>}</label>
                    <input type="text" className="form-control" placeholder="Email" name="email" ref={register({
                        required: true,
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Nesprávny tvar emailovej adresy"
                        }
                    })}/>
                </div>
                <div className="form-row">
                    <label htmlFor="message"><Trans>Správa</Trans> {errors.message && <span className="requirements"><Trans>Povinný údaj</Trans></span>}</label>
                    <textarea name="message" className="form-control" ref={register({required: true})} rows="5"/>
                </div>

                <div className="form-row">
                    <Reaptcha sitekey={process.env.RECAPTCHA_API_KEY} onVerify={onVerify}/>
                    {showValid && <div className="requirements">Povinný údaj</div>}
                </div>

                <div className="form-row">
                   <button type="submit" id="frmform-send"><Trans>Odoslať správu</Trans></button>
                </div>

            </form>
        }
    </div>
  );
}
